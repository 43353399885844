<template>
	<div>
		<div class="main">
			<v-list dense class="mb-5">
				<v-list-item>
					<v-dialog
						ref="dialog"
						v-model="modal"
						:return-value.sync="dateString"
						persistent
						width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div 
							v-bind="attrs"
							v-on="on"
							class="dateTextFieldWrapper">
								<v-text-field
								v-model="computedDateFormatted"
								prepend-inner-icon="mdi-calendar-month primary--text" 
								readonly
								v-bind="attrs"
								v-on="on"
								dense
								></v-text-field>
							</div>
						</template>
						<v-date-picker
							v-model="dateString"
							scrollable
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="modal = false"
							>{{lbuttons.cancel}}</v-btn>

							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(dateString)"
							>{{lbuttons.save}}</v-btn>
						</v-date-picker>
					</v-dialog>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="mr-6">
						<v-list-item-title>{{lviews.since}}</v-list-item-title>
						<v-text-field
						:value="findFirstInLastOut(records).firstIn.hour? fixHourString(findFirstInLastOut(records).firstIn.hour): '---'"
						prepend-inner-icon="mdi-login-variant primary--text" 
						readonly
						dense
						></v-text-field>
					</v-list-item-content>
					<v-list-item-content>
						<v-list-item-title>{{lviews.to}}</v-list-item-title>
						<v-text-field
						:value="findFirstInLastOut(records).lastOut.hour? fixHourString(findFirstInLastOut(records).lastOut.hour): '---'"
						prepend-inner-icon="mdi-logout-variant primary--text"
						readonly
						dense
						></v-text-field>
					</v-list-item-content>
				</v-list-item>

				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="font-weight-bold">{{lviews.totalWorktime}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item >
					<v-list-item-icon class="mr-2 mt-1">
						<v-icon class="primary--text">mdi-clock-time-nine-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="pa-0 ma-0">
						<v-list-item-title class="my-2 mt-0 font-weight-regular">{{timeToString(workTimeDaily(records, projects))}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- <v-list-item>
					<v-text-field
					value=""
					:label="dayMessage"
					></v-text-field>
				</v-list-item> -->

				<v-list-item>
					<v-list-item-content class="pa-0">
						<v-list-item-title class="font-weight-bold">{{lviews.projects}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item v-for="project in projects" :key="project">
					<v-list-item-content class="pt-2 pb-0">
						<v-list-item-title >{{getProjectName(project)}}</v-list-item-title>
	
					<v-list dense class="py-0">
						<v-list-item class="pl-0" v-for="item in loginLogoutPairs(projectRecords(project, records))" :key="item.id">
							<v-list-item-content class="pt-1 pb-0">
								<v-list-item-title class="grey--text font-weight-regular">
									{{fixHourString(item.login.hour)}} - {{fixHourString(item.logout.hour)}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-content class="pt-1 pb-0">
								<v-list-item-title class="font-weight-regular">
									<v-icon small class="primary--text mr-2">mdi-clock-time-nine-outline</v-icon>{{timeToString(workTimeProject(item))}}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!-- Undefined records -->
						<v-list-item class="pa-0" v-for="item in projectRecords(project, undefinedRecords)" :key="item.id">
							<v-list-item-icon class="mx-0 mt-1 mb-0">
								<v-icon class="red--text" small>mdi-help-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="pt-1 pb-0">
								<v-list-item-title class="grey--text font-weight-regular">
									{{item.hour}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					</v-list-item-content>

					<v-list-item-icon class="mt-3">
						<!-- Ikona do wglądu komentarzy - na razie wywalamy -->
						<!-- <v-badge
						color="orange"
						dot
						offset-x="14"
						offset-y="5"
						>
							<v-icon class="grey--text mr-3">mdi-message-reply-text-outline</v-icon>
						</v-badge> -->
						<v-menu offset-y v-if="addItemPermission(project)">
							<template v-slot:activator="{ on, attrs }">
								<v-icon 
								v-bind="attrs"
								v-on="on">
								mdi-dots-vertical
								</v-icon>
							</template>
							<v-list dense class="pa-0">
								<EditRecord @refreshData="refreshData()" :addItemPermission="addItemPermission(project)" :title="lviews.editRecords" :componentType="0" :project="project" :undefinedRecords="projectRecords(project, undefinedRecords)" :date="mutableDate" :worker_id="mutableWorkerId" :allWorkerProjects="allWorkerProjects" :loginLogoutPairs="loginLogoutPairs(projectRecords(project, records))" ></EditRecord>
							</v-list>
						</v-menu>
					</v-list-item-icon>
				</v-list-item>	
			</v-list>

			<EditRecord v-if="dropdownProjects.length>0"  @refreshData="refreshData()" :title="lviews.addRecords" :componentType="1" :worker_id="mutableWorkerId" :date="mutableDate" :dropdownProjects="dropdownProjects" :allWorkerProjects="allWorkerProjects"></EditRecord>
		
		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		</div>
	</div>
</template>

			
			

<script>
//import EditRecord from './EditRecord.vue'
const EditRecord = () => import('./EditRecord.vue');
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {
	name: 'RecordDetails',

	components: {
		EditRecord,
	},

	props: ['date', 'title', 'worker_id'],
	data: function(){
		return {
			modal:false,
			dayMessage: "Opis dnia...",
			records: [],
			mutableTitle: this.title,
			mutableDate: new Date(this.date),
			dateString: "",
			mutableWorkerId: this.worker_id,
			allWorkerProjects: [],
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
			workerAccess: [],
			projectAccess: []
		}
	},
	computed: {
		lviews: { get: function () { return this.$t('views.recordDetails'); } },
		lbuttons: { get: function () { return this.$t('commons.buttons'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },
		projects(){
			let projects=new Set()
			if(this.records){
				for (let record of this.records){
					projects.add(record.project)
				}
			}
			return [...projects].sort()
		},
		dropdownProjects(){ 
			let ddprojects=[]
			for(let item of this.allWorkerProjects){
				if(!this.projects.includes(item.project)){
					if(item.project_name && this.addItemPermission(item.project)){
						ddprojects.push(item.project_name)
					}
				}
			}
			ddprojects.sort()
			return ddprojects
		},
		computedDateFormatted () {
			return this.formatDate(this.dateString)
		},
		selectedWorkerPermissions(){
			let permissions={
				add: false,
				view: false,
				delete: false,
				change: false
			}
			if(this.workerAccess){
				for(let item of this.workerAccess){
					if(item.id==this.mutableWorkerId){
						permissions.add=item.add
						permissions.view=item.view
						permissions.delete=item.delete
						permissions.change=item.change
					}
				}
			}
			return permissions
		},
		undefinedRecords(){
			let undefinedRecords=[]
			if(this.records){
				for(let item of this.records){
					if(item.type==2){
						undefinedRecords.push(item)
					}
				}
			}
			return undefinedRecords
		}
	},
	mounted() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		if(this.mutableTitle && this.mutableDate && this.mutableWorkerId){
			localStorage.detailsTitle=JSON.stringify(this.mutableTitle)
			localStorage.detailsDate=JSON.stringify(this.mutableDate)
			localStorage.detailsWorkerId=JSON.stringify(this.mutableWorkerId)
		}
		else{
			this.mutableTitle = JSON.parse(localStorage.detailsTitle)
			this.mutableDate = new Date(JSON.parse(localStorage.detailsDate))
			this.mutableWorkerId = JSON.parse(localStorage.detailsWorkerId)
		}
		this.dateString = this.mutableDate.toISOString().substr(0, 10)
		this.setTitle()
		this.getGoBackLink()
		this.getDataFromApi()
		this.getProjectsFromApi()
	},
	watch: {
		dateString: function(){
			this.mutableDate=new Date(this.dateString)
		},
		mutableDate: function(){
			if(this.mutableDate){
				localStorage.detailsDate=JSON.stringify(this.mutableDate)
			}
			this.refreshData()
		}
	},
	methods: {
		addItemPermission(projectId){
			if(this.selectedWorkerPermissions.add){
				for(let i=0;i<this.projectAccess.length;i++){
					if(this.projectAccess[i].id==projectId){
						if(this.projectAccess[i].add) return true
					}
				}
			}
			return false
		},
		editItemPermission(projectId){
			if(this.selectedWorkerPermissions.change){
				for(let i=0;i<this.projectAccess.length;i++){
					if(this.projectAccess[i].id==projectId){
						if(this.projectAccess[i].change) return true
					}
				}
			}
			return false
		},
		deleteItemPermission(projectId){
			if(this.selectedWorkerPermissions.delete){
				for(let i=0;i<this.projectAccess.length;i++){
					if(this.projectAccess[i].id==projectId){
						if(this.projectAccess[i].delete) return true
					}
				}
			}
			return false
		},
		formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
      },
		getDataFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER");
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/cards/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
							},
					params: {
						'date_from': this.mutableDate.toISOString().substr(0, 10),
						'date_to': this.mutableDate.toISOString().substr(0, 10),
					}
				}).then((response) => {
					let currentDate = response.data.items.dates.find(item => this.mutableDate.toISOString().substr(0, 10) in item)
					if(currentDate){
						let records=currentDate[this.mutableDate.toISOString().substr(0, 10)].find(item=>item.worker_id==this.mutableWorkerId)
						if(records){
							this.records=records.card_scans
						}
					}
					for(let item of this.records){
						item.hour=this.fixHourString(item.hour)
					}
					this.workerAccess=response.data.items.worker_access
					this.projectAccess=response.data.items.project_access
					this.$emit("set-state",  "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state",  "DEFAULT");
					this.handleError(error)
				})
		},
		getProjectsFromApi(){
			this.$emit("set-state",  "LOADING_OVERLAYER", "Loading data...");
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/summary/worker-projects/"+this.mutableWorkerId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
							}
				}).then((response) => {
					this.allWorkerProjects=response.data.projects
					this.$emit("set-state",  "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state",  "DEFAULT");
					this.handleError(error)
				})
		},
		handleError(error){
			if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = this.lmessages.networkErrorTitle;
					this.errorDialog.errorMessage = this.lmessages.networkError;
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lmessages.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = this.lmessages.tooManyRequestsErrorTitle;
						this.errorDialog.errorMessage = this.lmessages.tooManyRequestsError;
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = this.lmessages.requestRejectedTitle;
						this.errorDialog.errorMessage = this.lmessages.requestRejected;
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = this.lmessages.internalServerErrorTitle;
						this.errorDialog.errorMessage = this.lmessages.internalServerError;
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = this.lmessages.errorOccuredTitle;
						this.errorDialog.errorMessage = this.lmessages.errorOccured;
						this.errorDialog.model = true;
						return;
				}
		},

		setTitle() {
			this.$emit('set-title', this.mutableTitle)
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "/timesheets");
		},
		refreshData(){
			this.records=[]
			this.getDataFromApi()
			this.$forceUpdate();
		},
		dateToString(date){
			if(!date){
				return ''
			}
			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			let day = date.getDate()
			if(day<10){
				day = "0"+day
			}
			return day+"."+month+"."+date.getFullYear()
		},
		getTomorrowDate(){
			let tomorrow = new Date(this.mutableDate)
			tomorrow.setDate(this.mutableDate.getDate() + 1)
			return tomorrow
		},
		loginLogoutPairs(cardScans){
			let pairs=[]
			if(cardScans){
				for(let i=0;i<cardScans.length;i++){
					let pair={
						id: pairs.length,
						project: "",
						login: "",
						logout: ""
					}
					if(cardScans[i].type==1){
						pair.project=cardScans[i].project
						// pair.logout=cardScans[i].hour
						pair.logout=cardScans[i]
						pairs.push(pair)
						continue
					}
					if(cardScans[i].type==0){
						// pair.login=cardScans[i].hour
						pair.login=cardScans[i]
						pair.project=cardScans[i].project
						if(i==cardScans.length-1){
							pairs.push(pair)
							break
						}
						if(cardScans[i+1].type==1 && cardScans[i].project==cardScans[i+1].project){
							// pair.logout=cardScans[i+1].hour
							pair.logout=cardScans[i+1]
							pairs.push(pair)
							i++
							continue
						}
						pairs.push(pair)
					}
				}
			}
			return pairs
		},
		projectRecords(project, records){
			let projectRecords=[]
			if(records){
				for(let item of records){
					if(item.project==project){
						projectRecords.push(item)
					}
				}
			}
			return projectRecords
		},
		workTimeProject(pair){
			if(!pair.login || !pair.logout){
				return 0
			}
			var time_start = new Date();
			var time_end = new Date();
			if(pair.login.hour>pair.logout.hour){
				time_end.setDate(time_end.getDate()+1)
			}
			var value_start = pair.login.hour.split(':');
			var value_end = pair.logout.hour.split(':');
			time_start.setHours(value_start[0], value_start[1], 0, 0)
			time_end.setHours(value_end[0], value_end[1], 0, 0)
			return (time_end-time_start)
		},
		workTimeDaily(records, projects){
			let projectPairs=[]
			for(let project of projects){
				projectPairs=projectPairs.concat(this.loginLogoutPairs(this.projectRecords(project, records)))
			}
			let time=0
			for(let pair of projectPairs){
				time+=this.workTimeProject(pair)
			}
			return time
		},
		timeToString(time){
			if(!time){
				return '---'
			}
			let hours = Math.floor(time/1000/3600)
			let minutes = ((time%3600000)/1000/60)
			if(hours<10){
				hours="0"+hours
			}
			if(minutes<10){
				minutes="0"+minutes
			}	
			return hours+":"+minutes
		},
		fixHourString(hour){
			if(!hour){
				return '?'
			}
			let splitHour=hour.split(':')
			let hours=splitHour[0]
			let minutes=splitHour[1]
			if(hours.length<2){
				hours='0'+hours
			}
			if(minutes.length<2){
				minutes='0'+minutes
			}
			return hours+":"+minutes
		},
		findFirstInLastOut(records){
			let firstIn={}
			let lastOut={}
			if(records){
				for(let item of records){
					if(item.type==0){
						firstIn=item
						break
					}
				}
				let index=records.indexOf(firstIn)
				for(let i=records.length-1;i>index;i--){
					if(records[i].type==1){
						lastOut= records[i]
						break
					}
				}
			}
			return {
				firstIn: firstIn,
				lastOut: lastOut
			}
		},
		getProjectName(projectId){
			if(this.allWorkerProjects){
				let project = this.allWorkerProjects.find(item => item.project==projectId) 
				if(project){
					return project.project_name
				}
			}
		}
	},
};
</script>

<style>

.v-text-field input {
	font-size: 0.9em;
}

.v-list-item{
	min-height: 20px !important;
}

.dateTextFieldWrapper{
	width: 100%;
}

.dateTextFieldWrapper :hover {
	cursor: pointer;
}


</style>
